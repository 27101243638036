<script lang="ts">
	import { getGlobalSettings } from "../../getGlobalSettings";
	import DynamicVideoInner from "./DynamicVideoInner.svelte";
	import type { Media } from "../../../../core/schema/Media.js";
	import { thumb } from "../../../../core/utils/thumb.js";
	import type { SourceSetQuery } from "../../utils/SourceSetQuery.js";

	export let video: Media;
	export let containerClass = "";
	export { containerClass as class };
	export let width: number | undefined = undefined;
	export let height: number | undefined = undefined;
	export let noLoop = false;
	export let autoplay = true;
	export let controls = false;
	export let notMuted = false;
	export let dontPlayInline = false;
	export let videoClass = "";
	export let icon = false;
	export let poster: Media | string | undefined = undefined;
	export let sourcesets: Partial<Record<SourceSetQuery, Media>> = {};
	export let loading: "lazy" | "eager" = "lazy";
	export let bucket: "storage" | "upload";
	export let disabled = false;

	const { STORAGE_URL, UPLOAD_URL } = getGlobalSettings();

	$: bucketUrl = {
		storage: STORAGE_URL,
		upload: UPLOAD_URL,
	}[bucket];

	$: posterImage =
		poster !== undefined ? (typeof poster === "string" ? poster : thumb(bucketUrl, 1920, 0, poster)) : undefined;
</script>

<div class="{containerClass} group relative">
	<DynamicVideoInner
		{video}
		{bucketUrl}
		{width}
		{height}
		{noLoop}
		{autoplay}
		{videoClass}
		{icon}
		{posterImage}
		{controls}
		{sourcesets}
		muted={!notMuted}
		playsinline={!dontPlayInline}
		lazy={loading === "lazy"}
		{disabled}
	/>
</div>
