<script lang="ts">
	import arrowDown from "../../../core/assets/icons/arrowDown.svg?raw";
	import Link from "./Link.svelte";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import type { Media } from "../../../core/schema/Media.js";
	import DynamicVideo from "./Video/DynamicVideo.svelte";
	import DynamicPicture from "./Picture/DynamicPicture.svelte";
	import { isFileVideo } from "../../../core/utils/isFileVideo.js";
	import { isFileImage } from "../../../core/utils/isFileImage.js";
	import { thumb } from "../../../core/utils/thumb.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import StaticVideo from "./Video/StaticVideo.svelte";
	import { nbspifyHtml } from "../../../core/utils/nbspifyHtml.js";

	export let backgroundImage: string | undefined = undefined;
	export let backgroundImageMobile: string | undefined = undefined;
	export let backgroundVideo: string | undefined = undefined;
	export let backgroundVideoMobile: string | undefined = undefined;
	export let backgroundMedia: Media | undefined = undefined;
	export let backgroundMediaMobile: Media | undefined = undefined;
	export let videoPoster: Media | string | undefined = undefined;
	export let videoPosterMobile: Media | string | undefined = undefined;
	export let backgroundImageClass: string | undefined = undefined;
	export let title: string;
	export let subheadline: string | undefined = undefined;
	export let titleClass: string | undefined = undefined;
	export let contentClass: string | undefined = undefined;
	export let hideShowMore = false;
	export let textColor: "white" | "black" = "white";
	export let overlay: "white-50" | "white-70" | "black" | "gray" | undefined = undefined;
	export let customOverlay: string | undefined = undefined;
	export let linkAsButton = false;
	export let linkText: string | undefined = undefined;
	export let linkAnchor: string | undefined = undefined;
	export let noLoopVideo = false;
	export let alignLeft = false;

	export let withoutContainer = false;

	let containerClass = "";
	export { containerClass as class };

	const { STORAGE_URL } = getGlobalSettings();
	$: poster =
		videoPoster !== undefined
			? typeof videoPoster === "string"
				? videoPoster
				: thumb(STORAGE_URL, 1920, 0, videoPoster)
			: undefined;
	$: posterMobile =
		videoPosterMobile !== undefined
			? typeof videoPosterMobile === "string"
				? videoPosterMobile
				: thumb(STORAGE_URL, 768, 0, videoPosterMobile)
			: undefined;

	/* eslint-disable svelte/no-at-html-tags */
</script>

<div class="{containerClass} relative flex min-h-screen justify-center overflow-hidden">
	{#if backgroundMedia}
		{#if backgroundMediaMobile && backgroundMedia.type.split("/")[0] !== backgroundMediaMobile.type.split("/")[0]}
			{#if isFileImage(backgroundMediaMobile)}
				<DynamicPicture
					bucket="storage"
					image={backgroundMediaMobile}
					width={768}
					height={1080}
					imgClass="h-full object-cover w-full {backgroundImageClass}"
					class="absolute left-0 top-0 h-full w-full {(overlay ?? customOverlay) !== undefined
						? 'opacity-80'
						: ''} md:hidden"
					alt={title}
					loading="eager"
				/>
			{:else if isFileVideo(backgroundMediaMobile)}
				<DynamicVideo
					autoplay
					noLoop={noLoopVideo}
					video={backgroundMediaMobile}
					videoClass="inset-0 absolute object-cover !h-full !w-full"
					class="!absolute inset-0 -z-10 {(overlay ?? customOverlay) !== undefined ? 'opacity-80' : ''} md:hidden"
					poster={posterMobile}
					bucket="storage"
					loading="eager"
				/>
			{/if}
		{/if}
		{#if isFileImage(backgroundMedia)}
			<DynamicPicture
				bucket="storage"
				image={backgroundMedia}
				width={1920}
				height={1080}
				imgClass="h-full object-cover w-full {backgroundImageClass}"
				class="absolute left-0 top-0 h-full w-full {(overlay ?? customOverlay) !== undefined
					? 'opacity-80'
					: ''} {backgroundMediaMobile && !isFileImage(backgroundMediaMobile) ? 'hidden md:block' : ''}"
				alt={title}
				loading="eager"
				sourcesets={backgroundMediaMobile && isFileImage(backgroundMediaMobile)
					? {
							"<md": [768, 1080, backgroundMediaMobile],
						}
					: undefined}
			/>
		{:else if isFileVideo(backgroundMedia)}
			<DynamicVideo
				autoplay
				noLoop={noLoopVideo}
				video={backgroundMedia}
				videoClass="inset-0 absolute object-cover !h-full !w-full"
				class="!absolute inset-0 -z-10 {(overlay ?? customOverlay) !== undefined
					? 'opacity-80'
					: ''} {backgroundMediaMobile && !isFileVideo(backgroundMediaMobile) ? 'hidden md:block' : ''}"
				{poster}
				bucket="storage"
				loading="eager"
				sourcesets={backgroundMediaMobile && isFileVideo(backgroundMediaMobile)
					? {
							"<md": backgroundMediaMobile,
						}
					: undefined}
			/>
		{/if}
	{:else if backgroundImage ?? backgroundVideo}
		{#if (backgroundImage && backgroundVideoMobile) ?? (backgroundVideo && backgroundImageMobile)}
			{#if backgroundImageMobile}
				<StaticPicture
					image={backgroundImageMobile}
					width={768}
					height={1080}
					imgClass="h-full object-cover w-full {backgroundImageClass}"
					class="absolute left-0 top-0 h-full w-full {(overlay ?? customOverlay) !== undefined
						? 'opacity-80'
						: ''} md:hidden"
					alt={title}
					loading="eager"
				/>
			{:else if backgroundVideoMobile}
				<StaticVideo
					autoplay
					noLoop={noLoopVideo}
					video={backgroundVideoMobile}
					videoClass="inset-0 absolute object-cover !h-full !w-full"
					class="!absolute inset-0 -z-10 {(overlay ?? customOverlay) !== undefined ? 'opacity-80' : ''} md:hidden"
					{poster}
					loading="eager"
				/>
			{/if}
		{/if}
		{#if backgroundImage}
			<StaticPicture
				image={backgroundImage}
				alt={title}
				width={1920}
				height={1080}
				imgClass="h-full object-cover w-full {backgroundImageClass}"
				class="absolute left-0 top-0 h-full w-full {(overlay ?? customOverlay) !== undefined
					? 'opacity-80'
					: ''} {backgroundVideoMobile ? 'hidden md:block' : ''}"
				loading="eager"
				sourcesets={backgroundImageMobile !== undefined
					? {
							"<md": backgroundImageMobile,
						}
					: undefined}
			/>
		{:else if backgroundVideo}
			<StaticVideo
				autoplay
				noLoop={noLoopVideo}
				video={backgroundVideo}
				videoClass="inset-0 absolute object-cover !h-full !w-full"
				class="!absolute inset-0 -z-10 {(overlay ?? customOverlay) !== undefined
					? 'opacity-80'
					: ''} {backgroundImageMobile ? 'hidden md:block' : ''}"
				{poster}
				loading="eager"
				sourcesets={backgroundVideoMobile !== undefined
					? {
							"<md": backgroundVideoMobile,
						}
					: undefined}
			/>
		{/if}
	{/if}

	{#if customOverlay}
		<div class="absolute inset-0 {customOverlay}"></div>
	{:else}
		{#if overlay === "white-70"}
			<div class="absolute inset-0 bg-white opacity-70"></div>
		{/if}
		{#if overlay === "white-50"}
			<div class="absolute inset-0 bg-white opacity-50"></div>
		{/if}
		{#if overlay === "black"}
			<div class="absolute inset-0 bg-black opacity-70"></div>
		{/if}
		{#if overlay === "gray"}
			<div class="absolute inset-0 bg-gradient-to-t from-black/30 to-[#686868]/50"></div>
		{/if}
	{/if}

	<div
		class="{contentClass} {!withoutContainer &&
			'container'} z-20 flex flex-col items-start justify-between pb-[4.1875rem] pt-[9.25rem] md:pb-20 md:pt-[8.5rem] lg:pt-[12.25rem] xl:static"
	>
		<h1
			class="{titleClass} {textColor === 'white'
				? 'text-secondary'
				: 'text-black'} m-0 w-full break-words p-0 text-2xl leading-[1.07] sm:text-3xl md:break-normal lg:w-5/6 lg:text-5xl"
		>
			{@html title}
		</h1>

		<div
			class="flex flex-col md:w-full md:flex-row md:items-end md:justify-between"
			class:md:!justify-end={!subheadline && !alignLeft}
		>
			{#if subheadline}
				<div>
					<h2 class="md:text-2lg text-secondary-200 m-0 p-0 text-lg leading-[1.3157]">
						{@html nbspifyHtml(subheadline)}
					</h2>
				</div>
			{/if}

			<slot />

			{#if !hideShowMore}
				<div class="hidden lg:flex">
					{#if linkAsButton}
						<Link asButton href={linkAnchor ?? "#showMore"}>
							{@html nbspifyHtml(linkText)}
						</Link>
					{:else}
						<Link
							href={linkAnchor ?? "#showMore"}
							class="text-2md
							{textColor === 'white' ? '!text-secondary' : '!text-black'} font-medium leading-8 no-underline"
							linkClass=""
							icon={arrowDown}
							iconClass="flex bg-[#00000080] w-[1.875rem] h-[1.875rem] rounded-full p-2 justify-center items-center"
						>
							{@html nbspifyHtml(linkText)}
						</Link>
					{/if}
				</div>
			{/if}
		</div>
	</div>
</div>
